const routes = {
  home: '/',
  faq: '/faq/',
  termsAndConditions: '/terms-and-conditions/',
  privacyPolicy: '/privacy-policy/',
  venues: '/venues/',
  venue: '/venues/:id',
  contactForm: '/contact-form/',
}

export default routes
