import React from 'react'
import { Container } from '@jsluna/react'
import Header from '../Header'
import Footer from '../Footer'
import './layout.scss'

const Layout: React.FC<{ testid?: string }> = ({ children, testid }) => {
  return (
    <Container size="lg" data-testid={testid}>
      <Header />
      <main className="content__wrapper">{children}</main>
      <Footer />
    </Container>
  )
}

export default Layout
