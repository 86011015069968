import React from 'react'
import { Link } from 'gatsby'
import config from '../../config'
import routes from '../../config/routes'

/**
 * Renders the application header.
 */
const Header = () => {
  const { premierSite, isSessionOpen } = config
  return (
    <div className="header__wrapper" data-testid="header_wrapper">
      <Link to={routes.home} className="header__logo logo">
        Active Kids Home
      </Link>
      <input
        className="menu_toggle"
        id="menu_toggle"
        name="menu_toggle"
        type="checkbox"
      />
      <label htmlFor="menu_toggle" />
      <ul className="header__nav" data-testid="header_nav">
        {!!isSessionOpen && (
          <li className="header__nav_item">
            <a href={`${premierSite}/bookings`} className="header__nav_link">
              <h5 className="mb0">View my account</h5>
            </a>
          </li>
        )}
        <li className="header__nav_item">
          <Link to={routes.termsAndConditions} className="header__nav_link">
            <h5 className="mb0">Terms and Conditions</h5>
          </Link>
        </li>
      </ul>
      <span className="premier_logo logo">Premier Education</span>
    </div>
  )
}
export default Header
