const envConfig = {
  isNectarDown: process.env.GATSBY_NECTAR_DOWN === 'true',
  isPremierDown: process.env.GATSBY_PREMIER_DOWN === 'true',
  apiEndpoint: process.env.GATSBY_API_ENDPOINT,
  venuesPath: process.env.GATSBY_VENUES_PATH,
  coursesPath: process.env.GATSBY_COURSES_PATH,
  premierSite: process.env.GATSBY_PREMIER_SITE,
  premierSiteCourse: `${process.env.GATSBY_PREMIER_SITE}/?course=`,
  nectarSite: process.env.GATSBY_NECTAR_SITE,
  googleMapsKey: process.env.GATSBY_GOOGLE_MAPS_KEY,
  useAnalytics: process.env.GATSBY_USE_ANALYTICS,
  logEndpoint: process.env.GATSBY_LOG_ENDPOINT,
  routerBase: process.env.GATSBY_ROUTER_BASENAME || '',
  buildNumber: process.env.GATSBY_BUILD_NUMBER,
  emailEndpoint: process.env.GATSBY_EMAIL_ENDPOINT,
  emailPath: process.env.GATSBY_EMAIL_PATH,
  tealiumEndpoint: process.env.GATSBY_TEALIUM_ENDPOINT,
  isSessionOpen: process.env.GATSBY_SESSION_OPEN === 'true',
  covid: process.env.GATSBY_COVID === 'true',
}

export default envConfig
