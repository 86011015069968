import React from 'react'

/**
 * Renders the hero image and the active kids holiday club that runs across the hero image.
 */

interface HeroProps {
  page?: boolean
  home?: boolean
}

const Hero: React.FC<HeroProps> = ({ children, home }) => {
  if (home) {
    return (
      <div className="hero" data-testid="hero_home">
        <div className="hero__inner">
          <div className="title">
            <h1 className="image_tagline_top">Sainsbury’s Active Kids</h1>
            <h2 className="image_tagline_bottom">Holiday Clubs</h2>
          </div>
          <div className="loz cta">
            <p className="cta__data">
              <span className="cta__data--1">ONLY</span>
              <span className="cta__data--2">£10</span>
              <span className="cta__data--3">per child per day</span>
              <span className="cta__data--4">including lunch</span>
            </p>
          </div>
          <div className="loz loz--small js-loz-01">
            <div className="loz__canvas loz--small__canvas" />
          </div>
          <div className="loz loz--medium js-loz-02">
            <div className="loz__canvas loz--medium__canvas" />
          </div>
          <div className="loz loz--large js-loz-03">
            <div className="loz__canvas loz--large__canvas" />
          </div>
          <div className="sainsburyslogo" />
        </div>
      </div>
    )
  }
  return (
    <div className="hero_image page" data-testid="hero_default">
      <div className="hero_image_container" />
      {children}
    </div>
  )
}

export default Hero
