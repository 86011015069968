import React from 'react'

interface UseFeatureProps {
  condition: boolean
}

const UseFeature: React.FC<UseFeatureProps> = ({ condition, children }) =>
  condition ? <>{children}</> : null

export default UseFeature
