import React from 'react'
import { Link } from 'gatsby'
import routes from '../../config/routes'
import UseFeature from '../UseFeature'

const Footer: React.FC = () => {
  return (
    <div className="footer__wrapper mt2 p1" data-testid="footer_wrapper">
      <ul className="footer__nav" data-testid="footer_nav">
        <li className="footer__nav_item">
          <Link to={routes.faq} className="footer__nav_link">
            FAQ
          </Link>
        </li>
        <li className="footer__nav_item">
          <Link to={routes.termsAndConditions} className="footer__nav_link">
            Terms &amp; Conditions
          </Link>
        </li>
        <li className="footer__nav_item">
          <a
            href="https://help.sainsburys.co.uk/help/website/our-commitment"
            rel="noopener noreferrer"
            target="_blank"
            className="footer__nav_link"
          >
            Active Kids Accessibility
          </a>
        </li>
        <li className="footer__nav_item">
          <Link to={routes.privacyPolicy} className="footer__nav_link">
            Privacy Policy
          </Link>
        </li>
        <li className="footer__nav_item">
          <a
            href="https://privacy-hub.sainsburys.co.uk/cookie-policy"
            rel="noopener noreferrer"
            target="_blank"
            className="footer__nav_link"
          >
            Cookie Policy
          </a>
        </li>
        <UseFeature
          condition={typeof window !== 'undefined' && window?.isOnetrustLoaded}
        >
          <li className="footer__nav_item">
            <button
              type="button"
              id="ot-sdk-btn"
              className="ot-sdk-show-settings footer__nav_link"
            >
              Manage Preferences
            </button>
          </li>
        </UseFeature>
        <li className="footer__nav_item">
          <a
            href="https://www.sainsburys.co.uk"
            rel="noopener noreferrer"
            target="_blank"
            className="footer__nav_link"
          >
            sainsburys.co.uk
          </a>
        </li>
        <li className="footer__nav_item">
          <a
            rel="noopener noreferrer"
            className="footer__nav_link"
            target="_blank"
            href="https://www.about.sainsburys.co.uk"
          >
            © J Sainsburys PLC
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Footer
